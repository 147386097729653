import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
  I18nextContext
} from "gatsby-plugin-react-i18next"


import { Content, ContainerWithBackground, ContentInner} from '../styles/global-styles';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import styled from "styled-components"

import SectionHeader from './section-header';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';



// import SimpleCard from '../components/our-model-card'
import SimpleCard from '../components/our-model-mini-card'

// const Container = styled.div`
//     width: 100%;
//     // padding-top: 60px;
//     text-align: center;
//     // background: #ACD433;
//     // background: #2c466b;
//     // color: wheat;
//     // margin-top: 15px;
//     margin: 0 auto;
//     background-image: url(${(props)=>props.backgroundImage});
    
//     @media screen and (max-width: 1220px) {
//         margin-top: 0;
//         padding-top: 0;
//     }
// `


const Container = styled(ContainerWithBackground)`
        @media screen and (max-width: 1220px) {
        margin-top: 0;
        padding-top: 0;
    }
`

const Section = styled.div`
    
`


const ContentWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    background-color: ${props=>props.$backgroundColour};
    color: ${props=>props.$colour};
    padding-top: 60px;
    padding-bottom: 60px;
    
            
    @media screen and (max-width: 1220px) {
        padding-top: 90px;
    }
    
        
    @media screen and (max-width: 767px) {
        padding-top: 73px;
    }
`

// const ContentInner = styled.div`
//     width: 73.306%;
//     margin: 0 auto;
// `

// const Content = styled.div`
//     margin: 0 auto;
//     margin-top: 40px;
//     max-width: 90%;
    
//     p { 
        
//         font-size: 20px;
//         line-height: 1.4;
//         margin-top: 14px;
//         font-weight: 400;
//         white-space: pre-wrap;
//         margin-bottom: 14px;
//         text-align: left;
//     }
// `

const AvatarsContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
`

const AvatarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    margin: 25px;
`

const StyledAvatar = styled(Avatar)`
    width: 160px;
    height: 160px;
    margin-bottom: 20px;
`

const Name = styled.h4`
    font-size: 20px;
    text-transform: uppercase;
`

const Titles = styled.p`
    font-size: 16px;
    text-align: center;
`

const useStyles = makeStyles((theme) =>
  createStyles({
    cardContainer: {
      	display: 'flex',
	    gap: '1em',
	    flexWrap: 'wrap',
	    justifyContent: 'center',
	    cursor: 'pointer',
	    
	    '& > *': {
    		// flexBasis: 275,
    		flexBasis: 230,
    		minWidth: '15em',
    		// backgroundColor: 'wheat',
    		backgroundColor: 'white',
    		transition: 'background 0.5s ease',
    		  ['@media screen and (max-width: 768px)']: {
    		        width: '100%',
    		        minWidth: 'initial',
    		        flexBasis: 'auto',
    		    },
    		    
    		'& .card-title': {
                // height: 64,
                height: 96,
                ['@media screen and (max-width: 1340px)']: {
    		        height: 64
                },
            },
            
            '& .card-text': {
                fontSize: 19,
            },
    		
    		'&:hover': {
    		    '& > *': {
    		    //backgroundColor: 'rgb(44,70,107)',
    		    backgroundColor: 'wheat',
    		    color: 'rgb(44,70,107)',
    		    transition: 'background 0.3s ease',
    		  
    		    '& .card-title': {
    		        color: 'rgb(44,70,107)'
    		    },
    		    
    		    '& .card-button': {
    		        color: 'rgb(44,70,107)'
    		    },
    		    
    		    '& .card-text': {
                    color: 'rgb(44,70,107)',
    		    },
    		    
    		    '& .card-title-link': {
    		    '&:hover': {
    		       
    		         '& .card-title': {
    		         
    		            color: 'rgb(44,70,107)',
    		            '-webkit-text-stroke': '0',
                        '-webkit-text-stroke-color': 'rgb(44,70,107)',
                            '-webkit-text-stroke-width': '0',
    		       
    		        },
    		        
    		    },
    		    },
    		    '& a': {
    		        '&:hover': {
    		            color: 'white',
    		            '-webkit-text-stroke': '0',
                        '-webkit-text-stroke-color': 'wheat',
                        '-webkit-text-stroke-width': '0',
    		        }     
    		  },
    		}
    		}
    		
	    },
    },
    cardTitle: {
        fontSize: 28,
        textTransform: 'uppercase',
        marginBottom: 32,
    },

  }),
);


const OurModelContent = (props) => {
    
    const query = useStaticQuery(
    graphql`
      query ModelsQuery {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(our-models)/"  }}  sort: {fields: frontmatter___order, order: ASC}) {
          edges {
            node {
                fields {
                    slug
                    # reference
                    category
                    # postId
                    path
                    langrefpath
                    langrefslug
                }
                frontmatter {
                    title
                    language
                    excerpt
                    category
                }
            }
          }
        }
      }
    `
  )
    const classes = useStyles();
    
    const context = React.useContext(I18nextContext);
    const currentLanguage = context.language;
    const {t} = useI18next();
    
    const edges = query.allMarkdownRemark.edges
    const models = edges.filter(edge=>edge.node.frontmatter.language == currentLanguage)
    
    return (
        
        <Container backgroundImage={props.backgroundImage}>
            <ContentWrapper >
                <SectionHeader colour={props.colour} dividerColour={props.dividerColour} title={props.title} subtitle={props.subtitle} subTitleColour={props.subTitleColour} withBackground={false}/>
                <Content className="transparent">
                   
                        <Box className={classes.cardContainer}>
                            {
                                models.map((model, index)=>{
                                
                                    return (
                                    <SimpleCard key={index} cardNumber={`${index}`} link={`${model.node.fields.langrefpath}`} title={model.node.frontmatter.title} body={model.node.frontmatter.excerpt}/>
                                    )
                          
                                })
                            }
                        </Box>
               
                </Content>
            </ContentWrapper>
        </Container>
        
    
    )
}

export default OurModelContent