import React from "react"
// import { Link } from "gatsby"

import {
  Link,
  Trans,
  useTranslation,
  useI18next,
  I18nextContext
} from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero-our-model"
import OurModelContent from "../components/our-model-content"

import bgImage from '../images/AdobeStock_261530621_Preview-original.jpg'
import ourModelsBackground from '../images/background-our-models.jpg'

const OurModelPage = (props) => {
    
    const context = React.useContext(I18nextContext);
    const currentLanguage = context.language;
    const {t} = useI18next();
    
    return (
      <Layout>
        <SEO title={t("MENU.OUR-MODEL")} />
        {/*<Hero />*/}
        <OurModelContent backgroundImage={ourModelsBackground} colour={"#ffffff"} dividerColour={"#ffffff"} title={t("OUR-MODEL.TITLE")} subtitle={t("OUR-MODEL.SUBTITLE")} subTitleColour={"#ffffff"} />
      </Layout>
    )
}
export default OurModelPage